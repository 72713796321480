import React from 'react';
import Recommended from '../data/Recommended';
import SocialLinks from '../data/SocialLinks';

function Footer() {
    const year = new Date().getFullYear();
    const scrollTop = () => document.getElementById('top').scrollIntoView({ behavior: 'smooth' });
    return (<>
        <footer>
            <div className="text-center">
                <div id="footer-social-links" className="col-12 mt-3">
                    <ul className="list-inline">
                        {SocialLinks.map(link =>
                            <li key={link.Title} className="list-inline-item">
                                <a className="icon fs-2" href={link.Url} rel="nofollow" target="_blank" title={`Follow on ${link.Title}`}>
                                    <span className={`icon-${link.Title.toLowerCase()}`}></span>
                                    <span className="visually-hidden">Follow on {link.Title}</span>
                                </a>
                            </li>
                        )}
                    </ul>
                </div>
                <h2 className="font-semibold">Recommended</h2>
                <div className="col-12 col-md-8 offset-md-2">
                {Recommended.map(link => 
                    <div key={link.Title} className="d-inline">
                        <a href={link.Url} rel="nofollow" target="_blank" title={link.Title}>
                            <img src={link.ImageUrl} className="img-fluid" alt={link.Title} />
                        </a>
                    </div>
                    )}
                </div>
                <div className="mt-3 mb-3">© Bitmigo 2016 - {year}</div>
            </div>
        </footer>
        <button className="btn btn-secondary" type="button" id="scroll-top" onClick={scrollTop}><span className="visually-hidden">top</span><span className="icon-up-open"></span></button>
        </>
    );

}export default Footer