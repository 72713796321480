import React, { useState } from 'react';
import Footer from './Footer';

function Layout({ children }) {

    const [navMenuCssClass, setNavMenuCssClass] = useState("wrapper");

    function toggleNavMenu() {

        if (navMenuCssClass === "wrapper") {
            setNavMenuCssClass("wrapper toggled");
        } else {
            setNavMenuCssClass("wrapper");
        }
    }

    return (
        <div id="wrapper" className={navMenuCssClass}>
            <div id="page-content-wrapper">
                <div id="sidebar-wrapper">
                    <ul className="sidebar-nav">
                        <li><a href="/">Home</a></li>
                        <li><a href="About">About</a></li>
                        <li><a href="Discography">Discography</a></li>
                        <li><a href="Playlist">Playlist</a></li>
                    </ul>
                </div>
                <button id="menu-toggle" className="btn btn-secondary" onClick={toggleNavMenu}>
                    <span className="visually-hidden">Menu</span>
                    <span className="icon-menu"></span>
                </button>
                <header id="top" className="text-center">
                    <a href="/" title="Home" className="brand-header">bitmigo</a>
                    <ul id="header-nav" className="list-inline">
                        <li className="list-inline-item"><a href="About">About</a></li>
                        <li className="list-inline-item"><a href="Discography">Discography</a></li>
                        <li className="list-inline-item"><a href="Playlist">Playlist</a></li>
                    </ul>
                </header>
                <div className="content container">
                    {children}
                </div>
                <Footer />
            </div>
        </div>
    );
  } export default Layout