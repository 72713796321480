import Home from "./components/Home";
import About from "./components/About";
import Discography from "./components/Discography";
import Playlist from "./components/Playlist";
import Download from "./components/Download";
import Release from "./components/Release";

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/about',
        element: <About />
    },
    {
        path: '/discography',
        element: <Discography />
    },
    {
        path: '/discography/:slug',
        element: <Release />
    },
    {
        path: '/playlist',
        element: <Playlist />
    },
    {
        path: '/download',
        element: <Download />
    },
];

export default AppRoutes;
