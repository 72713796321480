import React from 'react';
import SpotifyPlayer from './SpotifyPlayer';

import { useDocumentTitle } from "../hooks/setDocumentTitle"
function Home() {
    useDocumentTitle("Experimental electronic music | Bitmigo");
    return (
        <div className="row">
            <div className="col-12">
                <SpotifyPlayer title="Listen on Spotify" src="https://open.spotify.com/embed/album/6Zn51JGwBOmmRsFo35tovE?utm_source=generator&theme=0" height="600"></SpotifyPlayer>
            </div>
        </div>
    );
} export default Home
