import React, { useState, useEffect } from 'react';
import SpotifyPlayer from './SpotifyPlayer';
import { useDocumentTitle } from "../hooks/setDocumentTitle";

function Playlist() {
    useDocumentTitle("Playlist | Bitmigo");

    const LoadingStates = {
        Loading: "Loading",
        Error: "Error",
        Loaded: "Loaded"
    }

    const [loadingState, setLoadingState] = useState(LoadingStates.Loading);
    const [latestCheckin, setLatestCheckin] = useState();

    //const json = {
    //    "recenttracks": {
    //        "track": [
    //            {
    //                "artist": {
    //                    "mbid": "7e30debb-3308-49ab-a5ac-6d49319a5705",
    //                    "#text": "The Bravery"
    //                },
    //                "streamable": "0",
    //                "image": [
    //                    {
    //                        "size": "small",
    //                        "#text": "https:\/\/lastfm.freetls.fastly.net\/i\/u\/34s\/216958f06c21e5d865915d2e8854dc46.jpg"
    //                    },
    //                    {
    //                        "size": "medium",
    //                        "#text": "https:\/\/lastfm.freetls.fastly.net\/i\/u\/64s\/216958f06c21e5d865915d2e8854dc46.jpg"
    //                    },
    //                    {
    //                        "size": "large",
    //                        "#text": "https:\/\/lastfm.freetls.fastly.net\/i\/u\/174s\/216958f06c21e5d865915d2e8854dc46.jpg"
    //                    },
    //                    {
    //                        "size": "extralarge",
    //                        "#text": "https:\/\/lastfm.freetls.fastly.net\/i\/u\/300x300\/216958f06c21e5d865915d2e8854dc46.jpg"
    //                    }
    //                ],
    //                "mbid": "034664bc-c01f-3ff3-9e63-5f0a7cb1539b",
    //                "album": {
    //                    "mbid": "3497d3fc-313a-331a-88eb-a5f94f7ddc2c",
    //                    "#text": "The Bravery"
    //                },
    //                "name": "An Honest Mistake",
    //                "url": "https:\/\/www.last.fm\/music\/The+Bravery\/_\/An+Honest+Mistake",
    //                "date": {
    //                    "uts": "1655385491",
    //                    "#text": "16 Jun 2022, 13:18"
    //                }
    //            }
    //        ]
    //    }
    //};

    useEffect(() => {
        fetch("/api/socialfeed", {
            method: "GET"
        })
            .then(response => {
                if (!response.ok) {
                    throw `Error : ${response.status} ${response.statusText} ${response.json}`;
                }
                return response.json()
            })
            .then(json => {
                setLatestCheckin(json.recenttracks.track[0]);
                console.debug(json);
                setLoadingState(LoadingStates.Loaded);
            }).catch(err => {
                setLoadingState(LoadingStates.Error);
            });
        //console.debug(json);
        //setLatestCheckin(json.recenttracks.track[0]);
        //setLoadingState(LoadingStates.Loaded);
    }, []);

    function RecentTrack() {
        switch (loadingState) {
            case LoadingStates.Loaded:
                return (<div class="m-4">
                    <a href={latestCheckin.url} target="_blank">{latestCheckin.artist["#text"]}-{latestCheckin.name} <span className="icon icon-link-ext"></span></a>
                </div>);
            case LoadingStates.Loading:
                return (<div className="m-4 text-center">loading...</div>);
            case LoadingStates.Error:
                return (<div className="m-4 text-center text-danger">Error...</div>);
        }
    }

    return (<>
        <div className="row">
            <div className="col-12 col-md-9 order-md-last">
                <h1 className="dashed-border">Playlist</h1>
                <SpotifyPlayer title="Bitmigo Recommends" src="https://open.spotify.com/embed/playlist/6wULxfAqyVRgTwIgB8i7Cp" height="400"></SpotifyPlayer>
            </div>
            <div className="col-12 col-md-3 order-md-first">
                <div className="panel-group">
                    <div className="panel panel-default">
                        <div className="dashed-border">
                            <h4 className="panel-title">What I am listening to</h4>
                        </div>
                        <div className="panel-body">
                            <RecentTrack></RecentTrack>
                        </div>
                        <div className="panel-footer">
                            <a className="btn btn-secondary" href="https://www.last.fm/user/yungkangaroo/listening-report/year" rel="nofollow" role="button" target="_blank" title="more tracks">more tracks<i className="icon icon-link-ext"></i></a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </>);
}export default Playlist