import React from 'react';
import SpotifyPlayer from './SpotifyPlayer';
import { useDocumentTitle } from "../hooks/setDocumentTitle";
import Releases from "../data/Releases";
import { useParams } from 'react-router-dom';

function Release() {

    let { slug } = useParams();
    const Release = Releases.find((r) => r.Slug == slug);
    useDocumentTitle(`${Release.Title} | Bitmigo`);

    return (<>
            <h1 className="dashed-border">{Release.Title}</h1>
        <div className="embed-responsive mt-3">
            <SpotifyPlayer title={Release.EmbedPlayer.Title} src={Release.EmbedPlayer.Url} height="400"></SpotifyPlayer>
            </div>
            <div className="text-center">
            <ul className="list-inline">
                {Release.Links.map(link => <li key={link.Title} className="list-inline-item mb-2">
                        <a className="btn btn-secondary" href={link.Url} rel="nofollow" target="_blank" title={`Listen on ${link.Title}`}>
                            <span className={`icon icon-${link.Title.toLowerCase()}`}></span>
                            <span> {link.Title}</span>
                        </a>
                    </li>
                    )}
                </ul>
            </div>
            </>);
} export default Release