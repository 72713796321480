import React from 'react';

const Recommended = [
    {
        "ImageUrl": "https://images-eu.ssl-images-amazon.com/images/I/61GfGP45EUL._SL160_.jpg",
        "Url": "https://amzn.to/2W5Tom2",
        "Title": "Clark - Death Peak"
    },
    {
        "ImageUrl": "https://images-eu.ssl-images-amazon.com/images/I/41XVpvzwGVL._SL160_.jpg",
        "Url": "https://amzn.to/2AU7xtC",
        "Title": "The Black Dog - Final Collected Vexations"
    },
    {
        "ImageUrl": "https://images-eu.ssl-images-amazon.com/images/I/51knnbWPysL._SL160_.jpg",
        "Url": "https://amzn.to/2W0wfBk",
        "Title": "Boards of Canada - Music Has The Right To Children"
    },
    {
        "ImageUrl": "https://images-eu.ssl-images-amazon.com/images/I/416uD13W8rL._SL160_.jpg",
        "Url": "https://amzn.to/2APA8QL",
        "Title": "Telfon Tel Aviv - IMMOLATE YOURSELF"
    },
    {
        "ImageUrl": "https://images-eu.ssl-images-amazon.com/images/I/51HFYHm3pEL._SL160_.jpg",
        "Url": "https://amzn.to/2AYJp97",
        "Title": "Trentem&#248;ller - Fixion"
    },
    {
        "ImageUrl": "https://images-eu.ssl-images-amazon.com/images/I/41%2B65ABbuTL._SL160_.jpg",
        "Url": "https://amzn.to/2S37ncY",
        "Title": "U.N.K.L.E - War Stories"
    },
    {
        "ImageUrl": "https://images-eu.ssl-images-amazon.com/images/I/61DbSQIZCJL._SL160_.jpg",
        "Url": "https://amzn.to/2T5woBM",
        "Title": "Boom Bip - Zig Zaj"
    },
    {
        "ImageUrl": "https://images-eu.ssl-images-amazon.com/images/I/51-QarIsIvL._SL160_.jpg",
        "Url": "https://amzn.to/2W684Bs",
        "Title": "Moderat - Moderat"
    },
    {
        "ImageUrl": "https://images-eu.ssl-images-amazon.com/images/I/41fseaDe-bL._SL160_.jpg",
        "Url": "https://amzn.to/2HlUy9t",
        "Title": "Plaid - scintilli"
    },
    {
        "ImageUrl": "https://images-eu.ssl-images-amazon.com/images/I/31I89JIBi2L._SL160_.jpg",
        "Url": "https://amzn.to/2RG2bg1",
        "Title": "Rival Consoles - Night"
    },
    {
        "ImageUrl": "https://images-eu.ssl-images-amazon.com/images/I/51yK03fewHL._SL160_.jpg",
        "Url": "https://amzn.to/2W7wevz",
        "Title": "Rone - Tohu"
    },
    {
        "ImageUrl": "https://images-eu.ssl-images-amazon.com/images/I/51ht2dBSqdL._SL160_.jpg",
        "Url": "https://amzn.to/2RYbA1x",
        "Title": "Lorn - Ask The Dust"
    }
];
export default Recommended