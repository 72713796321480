import React from 'react';
import { useDocumentTitle } from "../hooks/setDocumentTitle";

function Download() {
useDocumentTitle("Download | Bitmigo");

    return (<>
                <h1 className="dashed-border">Download</h1>
                <div className="col-12">
                    <iframe className="bandcamp-player" title="Download on Bandcamp" src="https://bandcamp.com/EmbeddedPlayer/album=713958685/size=large/bgcol=333333/linkcol=ffffff/artwork=small/transparent=true/" seamless><a href="http://bitmigo.bandcamp.com/album/bestmentum">Bestmentum by bitmigo</a></iframe>
                </div>
        </>);
}export default Download