import React from 'react';
import { useDocumentTitle } from "../hooks/setDocumentTitle";
import AssociatedLinks from "../data/AssociateLinks";

function About() {
    useDocumentTitle("About | Bitmigo");

    function Link(props) {
        if (props.href !== '') {
            return <li><a rel="nofollow noopener" href={props.href} target="_blank">{props.title} <span className="icon-link-ext"></span></a></li>
        } else {
            return <li>{ props.title }</li>
        }
    }

    return (<>
        <div className="row">
            <div className="col-12 col-md-9 order-md-last">
                <h1 className="dashed-border">About</h1>
                <p>Welcome to the sound of Bitmigo thank you for taking the time to visit my site and listen to the music I am making, I like to think of this as an electronic exploration of all my favourite genres mashed up into something new or just a bit weird.</p>
                <p>I have been interested in electronic music production as a hobby since 2000. One of my earliest memories is listening to Orchestral Manoeuvres in the Dark and Emerson Lake and Palmer's Pictures at an exhibition on a Sony Walk-man while on a school trip, since then I have been fascinated with Electronic music, sound production and anything electronic that is a bit different.</p>
                <iframe scrolling="no" title="Follow on Bandcamp" className="bandcamp-follow" src="https://bandcamp.com/band_follow_button_classic/3178087857"></iframe>
                <h4 className="dashed-border">Kitlist</h4>
                <ul>
                    {AssociatedLinks.map(link => <Link key={link.Title} href={link.Url} title={link.Title}></Link>)}
                </ul>
            </div>
            <div className="col-12 col-md-3 order-md-first">
                <div className="panel-group">
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h4 className="panel-title dashed-border">Listen Now</h4>
                        </div>
                        <div className="panel-body">
                            <a className="video" href="https://www.youtube.com/embed/T5wUQVxRHek" rel="gallery" title="Listen to Bestmentum on Youtube" target="_blank">
                                <img alt="Bestmentum" className="img-fluid" src="/images/tonal-cypher-1400x1400.webp" />
                                <i className="icon-play btn btn-secondary"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}
export default About