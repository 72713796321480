import React from 'react';
import { useDocumentTitle } from "../hooks/setDocumentTitle";
import Releases from "../data/Releases";

function Discography() {
useDocumentTitle("Discography | Bitmigo");

    return (<>
            <div className="dashed-border">
                <h1 className="font-thin">Discography</h1>
            </div>
            <div className="row mt-3">
            {Releases.map(release => <div key={release.Title} className="col-12 col-sm-6 col-md-4">
                <div className="thumbnail">
                        <a href={release.Url} title={release.Title}>
                            <img alt={release.TitleCoverArt} className="img-fluid" src={release.ImageUrl} />
                        </a>
                        <div className="caption">
                            <a className="btn btn-link" href={release.Url} role="button" title={release.Title}>{release.Title}</a>
                        </div>
                    </div>
                </div>
                )}
            </div>
    </>);
} export default Discography