import React from 'react';

const Releases = [
    //{
    //    "Links": [
    //        {
    //            "Url": "https://open.spotify.com/album/2RHOqmVT9CzoNNAtVqNNtJ?si=VHAMQpjxSCC4S4WBvJiLmg",
    //            "Title": "Spotify"
    //        },
    //        {
    //            "Url": "https://soundcloud.com/bitmigo/sets/bestmentum",
    //            "Title": "Soundcloud"
    //        },
    //        {
    //            "Url": "https://bitmigo.bandcamp.com/album/bestmentum",
    //            "Title": "Bandcamp"
    //        },
    //        {
    //            "Url": "https://music.apple.com/us/album/bestmentum/1540439201",
    //            "Title": "Apple"
    //        },
    //        {
    //            "Url": "https://www.amazon.co.uk/dp/B08NNMVD75/ref=sr_1_13?dchild=1&keywords=bitmigo&qid=1605609602&sr=8-13",
    //            "Title": "Amazon"
    //        },
    //        {
    //            "Url": "https://music.youtube.com/playlist?list=OLAK5uy_m0Oq2Wo-th083JkzC7rykMdmic1_RMdZw",
    //            "Title": "Youtube"
    //        },
    //        {
    //            "Url": "https://www.deezer.com/us/album/186423742",
    //            "Title": "Deezer"
    //        },
    //        {
    //            "Url": "https://us.napster.com/artist/bitmigo/album/bestmentum",
    //            "Title": "Napster"
    //        }
    //    ],
    //    "EmbedPlayer": {
    //        "Url": "https://open.spotify.com/embed/album/2RHOqmVT9CzoNNAtVqNNtJ?utm_source=generator&theme=0",
    //        "Title": "Trambles (2022)"
    //    },
    //    "ImageUrl": "/images/trambles-cover.jpg",
    //    "Url": "/discography/trambles-2022/",
    //    "Title": "Trambles (2022)",
    //    "Slug": "trambles-2022"
    //},
  {
    "Links": [
      {
        "Url": "https://open.spotify.com/album/6Zn51JGwBOmmRsFo35tovE?si=BTnM2StzTJ-le4pSBSIE_A",
        "Title": "Spotify"
      },
      {
        "Url": "https://bitmigo.bandcamp.com/album/zaharoff",
        "Title": "Bandcamp"
      },
      {
        "Url": "https://music.apple.com/us/album/zaharoff/1541071923",
        "Title": "Apple"
      },
      {
        "Url": "https://www.amazon.co.uk/Zaharoff-Bitmigo/dp/B08NV6SHR4",
        "Title": "Amazon"
      },
      {
        "Url": "https://music.youtube.com/playlist?list=OLAK5uy_kvbMAheUq3uvAMLCwFlnJ13inJIy2Rs0E",
        "Title": "Youtube"
      },
      {
        "Url": "https://www.deezer.com/us/album/187429552",
        "Title": "Deezer"
      },
      {
        "Url": "https://us.napster.com/artist/bitmigo/album/zaharoff",
        "Title": "Napster"
      },
      {
        "Url": "https://www.kkbox.com/jp/en/album/7THyPuflCKthT0F6jWE1009H-index.html",
        "Title": "KKBox"
      }
    ],
    "EmbedPlayer": {
        "Url": "https://open.spotify.com/embed/album/6Zn51JGwBOmmRsFo35tovE?utm_source=generator&theme=0",
        "Title": "Zaharoff (2020)"
    },
    "ImageUrl": "/images/bandcamp-cover.webp",
    "Url": "/discography/zaharoff-2020/",
    "Title": "Zaharoff (2020)",
    "Slug": "zaharoff-2020"
  },
  {
    "Links": [
      {
        "Url": "https://open.spotify.com/album/2RHOqmVT9CzoNNAtVqNNtJ?si=VHAMQpjxSCC4S4WBvJiLmg",
        "Title": "Spotify"
      },
      {
        "Url": "https://soundcloud.com/bitmigo/sets/bestmentum",
        "Title": "Soundcloud"
      },
      {
        "Url": "https://bitmigo.bandcamp.com/album/bestmentum",
        "Title": "Bandcamp"
      },
      {
        "Url": "https://music.apple.com/us/album/bestmentum/1540439201",
        "Title": "Apple"
      },
      {
        "Url": "https://www.amazon.co.uk/dp/B08NNMVD75/ref=sr_1_13?dchild=1&keywords=bitmigo&qid=1605609602&sr=8-13",
        "Title": "Amazon"
      },
      {
        "Url": "https://music.youtube.com/playlist?list=OLAK5uy_m0Oq2Wo-th083JkzC7rykMdmic1_RMdZw",
        "Title": "Youtube"
      },
      {
        "Url": "https://www.deezer.com/us/album/186423742",
        "Title": "Deezer"
      },
      {
        "Url": "https://us.napster.com/artist/bitmigo/album/bestmentum",
        "Title": "Napster"
      }
    ],
    "EmbedPlayer": {
        "Url": "https://open.spotify.com/embed/album/2RHOqmVT9CzoNNAtVqNNtJ?utm_source=generator&theme=0",
      "Title": "Bestmentum (2016)"
    },
    "ImageUrl": "/images/tonal-cypher-1400x1400.webp",
    "Url": "/discography/bestmentum-2016/",
    "Title": "Bestmentum (2016)",
    "Slug": "bestmentum-2016"
    }
];
export default Releases