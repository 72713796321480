import React from 'react';


const SocialLinks = [
  {
    "Url": "https://open.spotify.com/artist/1OCge0LHoIA0O8h3iqFp1M?si=J6P-vZlKTACewZha_fu1pg",
    "Title": "Spotify"
  },
  {
    "Url": "https://bitmigo.bandcamp.com/releases",
    "Title": "Bandcamp"
  },
  {
    "Url": "https://www.youtube.com/@bitmigo",
    "Title": "Youtube"
  },
  {
    "Url": "https://www.last.fm/music/bitmigo",
    "Title": "Lastfm"
  },
  {
    "Url": "https://www.twitter.com/bitmigo",
    "Title": "Twitter"
  },
  {
    "Url": "https://instagram.com/bitmigomusic",
    "Title": "Instagram"
  }
];
export default SocialLinks