import React from 'react';

const AssociatedLinks = [
    {
        "Url": "https://amzn.to/3nI0mKV",
        "Title": "Ableton Live Suite"
    },
    {
        "Url": "https://amzn.to/3firmxy",
        "Title": "Native Instruments Komplete Ultimate"
    },
    {
        "Url": "",
        "Title": "Arturia V Collection"
    },
    {
        "Url": "https://amzn.to/3kNNb9A",
        "Title": "Akai MPK mini"
    },
    {
        "Url": "https://amzn.to/3flupFd",
        "Title": "Akai APC mini"
    },
    {
        "Url": "https://amzn.to/3kLoqut",
        "Title": "Alesis Multimix 4"
    },
    {
        "Url": "",
        "Title": "Roland GI-20"
    },
    {
        "Url": "",
        "Title": "BOSS ME-50 Multi Guitar Effects"
    },
    {
        "Url": "",
        "Title": "Tanglewood Pioneer 2 Electric Guitar"
    },
    {
        "Url": "",
        "Title": "Quad Core Xeon 3.3 GHz"
    },
    {
        "Url": "",
        "Title": "16Gb Ram"
    },
    {
        "Url": "",
        "Title": "AMD Radeon R9 280x"
    },
    {
        "Url": "https://distrokid.com/vip/seven/1568438",
        "Title": "Distrokid"
    }
];

export default AssociatedLinks